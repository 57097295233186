import throttle from 'lodash.throttle'
import Sizes from '@/js/components/sizes'

export default class Navbar {
  constructor() {
    this.body = document.querySelector('body')
    this.navbar = document.querySelector('nav.navbar.is-main')
    this.navbarMini = document.querySelector('nav.navbar.is-mini')
    this.burger = this.navbar.querySelector('.is-burger')
    this.menu = this.navbar.querySelector('.navbar-menu')
    this.active = false
    this.wasActive = false
    this.dropdown = null
    this.themeBtns = this.navbar.querySelectorAll('.button.is-light')
    this.toggleBtn = this.navbarMini.querySelector('.button.js-toggle')
    this.topBtn = this.navbar.querySelector('.js-tothetop')

    this.burger.addEventListener('click', (e) => {
      const trigger = e.target.closest('.js-burger')

      if (trigger) {
        this.active = !this.active
        this.body.classList.toggle('is-clipped', this.active)
        this.menu.classList.toggle('is-active', this.active)
        this.burger.classList.toggle('is-active', this.active)
        trigger.setAttribute('aria-expanded', this.active)
        this.topBtn.classList.toggle('is-hidden', this.active)
        document.documentElement.classList.toggle('is-clipped', this.active)
      }
    })

    if (!Sizes.isMobile) {
      this.initMiniNav()
      if (this.themeBtns?.length) this.initHoverTheme()
    }

    this.initSubmenu()
  }

  initMiniNav() {
    const scrollLimit = 300

    this.toggleBtn.addEventListener('click', () => {
      this.navbar.classList.add('is-active')
      this.navbarMini.classList.remove('is-active')
    })

    const checkScroll = () => {
      const pageY = window.scrollY

      this.isMini = pageY > scrollLimit
      this.navbarMini.classList.toggle('is-active', this.isMini)
      this.navbar.classList.remove('is-active')
    }

    document.addEventListener('scroll', throttle(checkScroll, 100))
  }

  initSubmenu() {
    this.trigger = null
    // const isPermanent = (d) => d.dataset?.permanent != null

    document.addEventListener('click', (e) => {
      const trigger = e.target.closest('[data-submenu]')

      if (trigger) {
        e.preventDefault()

        const dropdown = document.querySelector(
          `.is-submenu[data-id="${trigger.dataset.submenu}"]`
        )

        if (dropdown) {
          if (this.dropdown && this.dropdown != dropdown) {
            this.clearActiveDropdown()
          }

          if (trigger.dataset.group) {
            const sTrigger = document.querySelector(
              '[data-submenu][data-group="' +
                trigger.dataset.group +
                '"].is-black'
            )
            const sDropdown = document.querySelector(
              '.is-submenu:not(.is-hidden)'
            )

            if (sTrigger && sDropdown) {
              sDropdown.classList.add('is-hidden')
              sTrigger.classList.remove('is-black')
            }
          } else {
            // save active dropdown for closing on click outside on non-permanent submenus
            this.dropdown = dropdown
            this.trigger = trigger
          }

          trigger.classList.toggle('is-black')
          dropdown.classList.toggle('is-hidden')

          if (this.dropdown && Sizes.isMobile) {
            dropdown.scrollIntoView({ behavior: 'smooth' })
          }
        }
      } else if (this.dropdown) {
        this.clearActiveDropdown()
      }
    })
  }

  clearActiveDropdown() {
    this.trigger.classList.remove('is-black')
    this.dropdown.classList.add('is-hidden')
    this.trigger = null
    this.dropdown = null
  }

  initHoverTheme() {
    if (!this.themeBtns[0].classList.contains('is-active')) {
      this.themeBtns.forEach((element) => {
        element.addEventListener('mouseenter', () => {
          this.themeBtns.forEach((e) => e.classList.add('is-active'))
        })

        element.addEventListener('mouseleave', () => {
          this.themeBtns.forEach((e) => e.classList.remove('is-active'))
        })
      })
    }
  }
}
